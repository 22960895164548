import { useState, useCallback, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./CarouselIndicator.module.css";
import template from "../../../helpers/template";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";

const cx = classNames.bind(styles);

export function CarouselIndicator({
  className,
  pageDuration = 2000,
  onSlideChange,
  length,
}: {
  className?: string;
  pageDuration?: number;
  length: number;
  onSlideChange?: (index: number) => Promise<void>;
}) {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const nextSlide = useCallback(async () => {
    const nextIndex = (selectedIndex + 1) % length;

    await onSlideChange?.(nextIndex);
    setSelectedIndex(nextIndex);
  }, [length, selectedIndex, onSlideChange]);

  const translations = useTranslationsContext();

  useEffect(() => {
    setSelectedIndex(0);
  }, []);

  return (
    <div
      className={cx("container", className)}
      aria-controls={template(
        translations["carousel.controls.controlsAriaLabel"],
        "Slide controls",
      )}
    >
      {Array.from({ length }).map((_, index) => (
        <button
          key={index}
          className={cx("indicator-button")}
          onClick={async () => {
            await onSlideChange?.(index);
            setSelectedIndex(index);
          }}
          aria-current={selectedIndex === index ? "true" : "false"}
          aria-selected={selectedIndex === index ? "true" : "false"}
          aria-roledescription={template(
            translations["carousel.controls.showSlideXOfY"],
            "Show slide",
            { count: `${index + 1}`, total: `${length}` },
          )}
          aria-controls={template(
            translations["carousel.controls.showSlideXOfY"],
            "Show slide",
            { count: `${index + 1}`, total: `${length}` },
          )}
        >
          <CarouselIndicatorItem
            active={selectedIndex === index}
            duration={pageDuration}
            onComplete={nextSlide}
          />
        </button>
      ))}
    </div>
  );
}

function CarouselIndicatorItem({
  active = false,
  duration,
  onComplete,
}: {
  active?: boolean;
  duration: number;
  onComplete?: () => void;
}) {
  return (
    <div className={cx("indicator")} data-active={active}>
      <div
        style={{ transition: `width ${duration}ms linear` }}
        className={cx("progress")}
        onTransitionEnd={active ? onComplete : undefined}
      />
    </div>
  );
}
