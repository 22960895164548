"use client";

import { CosmosButton, CosmosText, CosmosTitle } from "@cosmos/web/react";
import styles from "./AthleteTeaser.module.css";
import classnames from "classnames/bind";
import { useRef, useState } from "react";
import { CarouselIndicator } from "./CarouselIndicator";
import { PersonProfileImage } from "../../../__generated__/crepo-types";
import { useLocaleStringContext } from "../../../helpers/LocaleStringContext";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { IconQuotationMark } from "../../IconQuotationMark";
import template from "../../../helpers/template";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";

const cx = classnames.bind(styles);

interface AthleteQuoteArticle {
  title: string;
  quote: string | null;
  storyInfo: string;
  image: PersonProfileImage | string | null;
  athleteInfo: {
    image: PersonProfileImage | string | null;
    name: string;
  };
  url: string | null;
}

export function AthleteTeaser({
  articles,
  pageDuration = 5000,
}: {
  articles: AthleteQuoteArticle[];
  pageDuration?: number;
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const elementsRefs = useRef<Array<HTMLDivElement | null>>([]);
  const translations = useTranslationsContext();

  if (articles.length === 0) {
    return <></>;
  }

  const runSlideOutAnimation = () => {
    return new Promise<() => void>((resolve) => {
      const activeElementRef = elementsRefs.current[currentIndex];
      if (!activeElementRef) {
        resolve(() => {});
        return;
      }

      activeElementRef.ontransitionend = () => {
        activeElementRef.ontransitionend = null;

        resolve(() => (activeElementRef.dataset.status = undefined));
      };

      activeElementRef.dataset.status = "animate-out";
    });
  };

  return (
    <div
      className={cx("container")}
      aria-roledescription={template(
        translations["carousel.carouselAriaRoleDescription"],
        "Carousel",
      )}
      aria-label={template(
        translations["carousel.atheleteQuotes.ariaLabel"],
        "Athlete Quotes",
      )}
    >
      {articles.map((article, index) => {
        const isActive = index === currentIndex;
        const isNext = index === (currentIndex + 1) % articles.length;

        return (
          <div
            ref={(el) => {
              elementsRefs.current[index] = el;
            }}
            key={index}
            className={cx("item")}
            data-role={isActive ? "active" : isNext ? "next" : undefined}
            aria-roledescription={template(
              translations["carousel.slide.slideAriaRoleDescription"],
              "Slide",
            )}
            aria-label={template(
              translations["carousel.slide.slideAriaLabel"],
              "Slide",
              {
                count: `${index + 1}`,
                total: `${articles.length}`,
              },
            )}
          >
            <AthleteQuoteSlide article={article} active={!isActive} />
          </div>
        );
      })}
      <CarouselIndicator
        className={cx("carousel-indicator")}
        length={articles.length}
        onSlideChange={async (index) => {
          const activeElementRef = elementsRefs.current[currentIndex];
          if (!activeElementRef) {
            return;
          }

          const resetActiveItemStatus = await runSlideOutAnimation();

          setCurrentIndex(index);
          resetActiveItemStatus();
        }}
        pageDuration={pageDuration}
      />
    </div>
  );
}

function AthleteQuoteSlide({
  article,
  active,
}: {
  article: AthleteQuoteArticle;
  active?: boolean;
}) {
  const localeString = useLocaleStringContext();
  const translations = useTranslationsContext();

  const imageInfo = (() => {
    const image = article.image;

    if (!image) {
      return { url: "", srcSet: "", alt: "" };
    }

    switch (typeof image) {
      case "string":
        return {
          url: image,
          alt: "", // Fallback to title if alt is not provided
          srcSet: responsiveImageHelper.createCrepoSrcSet(image, localeString),
        };

      case "object": {
        const imageUrl =
          image.imageEssence.__typename === "CloudinaryImage"
            ? image.imageEssence.imageURL
            : image.imageSrc ?? "";

        return {
          url: imageUrl,
          alt: image.title?.text ?? "",
          srcSet: imageUrl
            ? responsiveImageHelper.createCrepoSrcSet(imageUrl, localeString)
            : "",
        };
      }
    }
  })();

  return (
    <>
      <div className={cx("featured-image")}>
        {article.image && (
          <img
            src={imageInfo.url}
            srcSet={imageInfo.srcSet}
            sizes="100vw"
            alt={imageInfo.alt ?? ""}
          />
        )}
        <div className={cx("backdrop")} />
      </div>
      <div className={cx("text-container")} data-id="animate-in">
        <div className={cx("text-conatiner-inner")}>
          <div className={cx("quote-info")}>
            <IconQuotationMark className={cx("quote-icon")} fill="white" />
            <CosmosTitle
              className={cx("title")}
              appearance="light"
              size="large"
            >
              {article.title}
            </CosmosTitle>
            <CosmosText
              className={cx("quote")}
              appearance="light"
              size="x=small"
            >
              {article.quote}
            </CosmosText>
          </div>
          <div className={cx("actions")} data-active={active}>
            <CosmosText
              className={cx("story-info")}
              appearance="light"
              size="x-small"
            >
              {article.storyInfo}
            </CosmosText>
            <AthleteButton
              image={article.athleteInfo.image}
              name={article.athleteInfo.name}
              active={active}
            />
            <CosmosButton href={article.url ?? "/"} target="_blank">
              {template(
                translations["carousel.atheleteQuotes.cta"],
                "Learn more",
              )}
            </CosmosButton>
          </div>
        </div>
      </div>
    </>
  );
}

function AthleteButton({
  image,
  name,
}: {
  image: PersonProfileImage | string | null;
  name: string;
  active?: boolean;
}) {
  const localeString = useLocaleStringContext();

  const imageInfo = (() => {
    if (!image) {
      return { url: "", srcSet: "", alt: "" };
    }

    switch (typeof image) {
      case "string":
        return {
          url: image,
          alt: "",
          srcSet: responsiveImageHelper.createCrepoSrcSet(image, localeString),
        };

      case "object": {
        const imageUrl =
          image.imageEssence.__typename === "CloudinaryImage"
            ? image.imageEssence.imageURL
            : image.imageSrc ?? "";

        return {
          url: imageUrl,
          alt: image.title?.text ?? "",
          srcSet: imageUrl
            ? responsiveImageHelper.createCrepoSrcSet(imageUrl, localeString)
            : "",
        };
      }
    }
  })();

  return (
    <div className={cx("athlete-button")}>
      {image && (
        <img
          className={cx("athlete-image")}
          src={imageInfo.url}
          alt={imageInfo.alt}
          srcSet={imageInfo.srcSet}
          sizes="(min-width: 1000px) min(510px, 42vw), (min-width: 650px) 63vw, 66vw"
        />
      )}
      <CosmosText
        className={cx("athlete-name")}
        appearance="light"
        size="x-small"
      >
        {name}
      </CosmosText>
    </div>
  );
}
