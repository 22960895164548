"use client";

import { CosmosButton } from "@cosmos/web/react";
import { TeaserBasic } from "../../TeaserBasic/TeaserBasic";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import classnames from "classnames/bind";
import styles from "./CompanyTeaser.module.css";
import { useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { getStoryblokVideoUrl } from "../../../helpers/images/getStoryblokVideoUrl";

const cx = classnames.bind(styles);

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export function CompanyTeaser({
  kicker,
  title,
  buttonLabel,
  videoSrc,
  href,
}: {
  kicker: string;
  title: string;
  buttonLabel: string;
  videoSrc: string;
  href: string;
}) {
  const componentWrapperRef = useRef<HTMLDivElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const teaserRef = useRef<HTMLDivElement>(null);
  const videoElementRef = useRef<HTMLVideoElement>(null);

  useGSAP(() => {
    const componentElement = componentWrapperRef.current;
    const videoContainerElement = videoContainerRef.current;
    const videoElement = videoElementRef.current;
    const teaserElement = teaserRef.current;

    const videoTimeline = gsap.to(videoContainerElement, {
      scale: 1,
    });

    const textTimeline = gsap
      .timeline({ ease: "none" })
      .from(teaserElement, { opacity: 0, yPercent: 15 })
      .to(teaserElement, {
        opacity: 1,
        yPercent: 0,
      });

    // Percentage from top of viewport 0 = top, 100 = bottom, use a raw number, no % character.
    const topVal = 25;

    // Text fade
    ScrollTrigger.create({
      trigger: componentElement,
      start: `top 50%`,
      end: `top ${topVal}%`,
      animation: textTimeline,
      scrub: true,
      markers: false,
    });

    // Video scale
    ScrollTrigger.create({
      trigger: componentElement,
      start: "top 66%",
      end: `top ${topVal}%`,
      animation: videoTimeline,
      scrub: true,
      markers: false,
    });

    // Play video
    if (videoElement) {
      ScrollTrigger.create({
        trigger: componentElement,
        start: "top bottom%",
        end: "bottom top%",
        // markers: true,
        onEnter: () => videoElement.play(),
        onEnterBack: () => videoElement.play(),
        onLeave: () => videoElement.pause(),
        onLeaveBack: () => videoElement.pause(),
      });
    }

    // Border Radius
    if (componentElement) {
      ScrollTrigger.create({
        trigger: componentElement,
        start: `top ${topVal + 10}%`,
        end: `top ${topVal}%`,
        scrub: false,
        onEnter: () =>
          componentElement.setAttribute("data-hide-corners", "true"),
        onLeaveBack: () =>
          componentElement.removeAttribute("data-hide-corners"),
        toggleActions: "play none none reverse",
        markers: false,
      });
    }

    return () => ScrollTrigger.killAll();
  }, []);

  // NOTE: adding `muted` to the video is required to stop error:
  // Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first
  return (
    <div className={cx("container")}>
      <div className={cx("inner")} ref={componentWrapperRef}>
        <div className={cx("video__wrapper")} ref={videoContainerRef}>
          <video
            ref={videoElementRef}
            className={cx("video__video")}
            src={getStoryblokVideoUrl(videoSrc)}
            autoPlay
            loop
            disablePictureInPicture
            playsInline
            muted
          />
          <div className={cx("video__overlay")} />
        </div>
        <div className={cx("teaser__container")} ref={teaserRef}>
          <TeaserBasic
            kicker={kicker}
            title={title}
            titleSize="xx-large"
            headingElement="title"
            appearance="light"
          >
            <ButtonWrapper>
              <CosmosButton className={cx("button")} size="large" href={href}>
                {buttonLabel}
              </CosmosButton>
            </ButtonWrapper>
          </TeaserBasic>
        </div>
      </div>
    </div>
  );
}
