"use client";

import { CosmosIconChevronRight, CosmosTitle } from "@cosmos/web/react";
import { TeaserBasic } from "../../TeaserBasic/TeaserBasic";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import classnames from "classnames/bind";
import styles from "./ProductBanderoleTeaser.module.css";
import { useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import {
  BuyNowMikmak,
  BuyNowMikmakProps,
} from "../../BuyNowMikmak/BuyNowMikmak";
import { BuyNowVendorLinks } from "../../BuyNowVendorLinks/BuyNowVendorLinks";
import { useId } from "react";
import { getSchemeById } from "../../../helpers/colorSchemes";
import { ButtonPcs } from "../../ButtonPcs/ButtonPcs";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getStoryblokImageDimension } from "../../../helpers/images/getStoryblokImageDimension";
import { getImageSource } from "../../../helpers/getImageSource";

const cx = classnames.bind(styles);

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

export function ProductBanderoleTeaser({
  kicker,
  title,
  banderoleText,
  imageUrl,
  imageAlt = "",
  vendorLinksMikmakIds,
  vendorLinksLabel,
  vendorLinks,
  ctaButtonLabel,
  ctaButtonUrl,
  colorSchemeKey,
}: {
  kicker: string;
  title: string;
  banderoleText: string;
  imageUrl: string;
  imageAlt?: string;
  vendorLinksMikmakIds?: BuyNowMikmakProps["mikmakIds"];
  vendorLinksLabel: string | null;
  vendorLinks?: {
    label: string | null;
    url: string | null;
    icon: string | null;
  }[];
  ctaButtonLabel: string | null;
  ctaButtonUrl: string | null;
  colorSchemeKey?: string;
}) {
  const imageLoading = useImageLoading();
  const imageSource = imageUrl ? getImageSource(imageUrl) : null;
  const imageDimensions = getStoryblokImageDimension(imageUrl);

  const id = useId();

  const componentWrapperRef = useRef<HTMLDivElement>(null);
  const outlineRef = useRef<HTMLDivElement>(null);
  const expanderRef = useRef<HTMLDivElement>(null);
  const expanderInnerRef = useRef<HTMLDivElement>(null);
  const canRef = useRef<HTMLImageElement>(null);

  const colorScheme = getSchemeById(colorSchemeKey);
  const colorSchemeTextAppearance = colorScheme.textAppearance ?? "dark";
  const colorSchemeSecondary = colorScheme.secondary;
  const colorCanShadowRGB = colorScheme.canShadowRGB;

  // If there are no vendor links or mikmaks, we need to apply some custom CSS changes
  const isCTAPrimary = !vendorLinks && !vendorLinksMikmakIds;

  useGSAP(() => {
    const componentElement = componentWrapperRef.current;
    const outlineElement = outlineRef.current;
    const expanderElement = expanderRef.current;
    const expanderInnerElement = expanderInnerRef.current;
    const canElement = canRef.current;

    gsap.to(outlineElement, {
      x: -800,
      ease: "none",
      scrollTrigger: {
        trigger: componentElement,
        start: "top top",
        end: "+=1500px",
        scrub: true,
      },
    });

    gsap.to(outlineElement, {
      opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: componentElement,
        start: "1000px top",
        end: "+=500px",
        scrub: true,
      },
    });

    const panel_tl = gsap.timeline({
      scrollTrigger: {
        trigger: componentElement,
        start: "1000px top",
        end: "+=500px",
        scrub: true,
      },
    });

    panel_tl.fromTo(expanderElement, { height: 0 }, { height: "auto" });

    const can_tl = gsap.timeline({
      scrollTrigger: {
        trigger: componentElement,
        start: "1000px top",
        end: "+=500px",
        scrub: true,
      },
    });

    can_tl.fromTo(canElement, { scale: 1 }, { scale: 1.4 });

    const expanderInner = gsap.timeline({
      scrollTrigger: {
        trigger: componentElement,
        start: "1500px top",
        end: "+=500px",
        scrub: true,
      },
    });

    expanderInner.fromTo(
      expanderInnerElement,
      { translateY: "-100%", opacity: 0 },
      { translateY: "0%", opacity: 1 },
    );

    const trigger = ScrollTrigger.create({
      trigger: componentElement,
      start: "top top",
      end: "+=2500",
      markers: false,
      scrub: true,
      pin: true,
    });

    /**
     * Workaround for GSAP/ScrollTrigger bug. Seems that when images load (or
     * there is any other kind of re-flow/re-layout that moves the element down
     * the page, then that invalidates ScrollTrigger's internal scroll
     * measurements.
     *
     * Rather than listening for loading images, let's just
     * observe the document's entire geometry, because components such as
     * accordions can also be responsible for this component's relative scroll
     * position to have changed.
     */
    const bodyResizeObserver = new ResizeObserver(() => trigger.refresh());

    bodyResizeObserver.observe(document.body);

    return () => bodyResizeObserver.disconnect();
  }, []);

  return (
    <div
      className={cx("container")}
      ref={componentWrapperRef}
      style={{
        "--scheme-secondary": colorSchemeSecondary,
        "--scheme-can-shadow-rgb": colorCanShadowRGB,
      }}
    >
      <div className={cx("inner")}>
        <div className={cx("content-wrap")}>
          <div className={cx("expander")} ref={expanderRef}>
            <div className={cx("expander-inner")}>
              <div
                className={cx("expander-inner-padding")}
                ref={expanderInnerRef}
              >
                <TeaserBasic
                  kicker={kicker}
                  title={title}
                  titleSize="xx-large"
                  headingElement="title"
                  appearance={colorSchemeTextAppearance}
                >
                  <ButtonWrapper className={cx("button-wrapper")}>
                    {vendorLinksMikmakIds && (
                      <BuyNowMikmak
                        mikmakIds={vendorLinksMikmakIds ?? null}
                        label={vendorLinksLabel ?? ""}
                        buttonSize="large"
                        className={cx("button")}
                        buttonCustomColor={colorScheme.customButtonColor}
                      />
                    )}
                    {vendorLinks && (
                      <BuyNowVendorLinks
                        id={id}
                        label={vendorLinksLabel ?? ""}
                        className={cx("button")}
                        buttonSize="large"
                        buttonAppearance={colorSchemeTextAppearance}
                        links={
                          vendorLinks.map((item) => ({
                            label: item?.label ?? "",
                            url: item?.url ?? "",
                            icon: item?.icon ?? null,
                          })) ?? []
                        }
                        analyticsElementPosition="VendorLink"
                        buttonCustomColor={colorScheme.customButtonColor}
                      />
                    )}

                    {ctaButtonLabel && ctaButtonUrl && (
                      <>
                        {/* Stop hydration errors */}

                        {/* Desktop */}
                        <ButtonPcs
                          href={ctaButtonUrl}
                          className={cx("cta-button")}
                          size="large"
                          kind={isCTAPrimary ? "primary" : "cover-up"}
                          appearance={colorSchemeTextAppearance}
                          iconPlacement="after"
                          data-breakpoint="tablet"
                          customColor={
                            isCTAPrimary
                              ? colorScheme.customButtonColor
                              : undefined
                          }
                        >
                          {ctaButtonLabel}
                        </ButtonPcs>

                        {/* Mobile */}
                        <ButtonPcs
                          href={ctaButtonUrl}
                          className={cx("cta-button")}
                          size="large"
                          kind={isCTAPrimary ? "primary" : "link"}
                          appearance={colorSchemeTextAppearance}
                          iconPlacement="after"
                          data-breakpoint="mobile"
                          customColor={
                            isCTAPrimary
                              ? colorScheme.customButtonColor
                              : undefined
                          }
                        >
                          {!isCTAPrimary && (
                            <CosmosIconChevronRight
                              slot="icon"
                              className={cx("button-icon")}
                            />
                          )}
                          {ctaButtonLabel}
                        </ButtonPcs>
                      </>
                    )}
                  </ButtonWrapper>
                </TeaserBasic>
              </div>
            </div>
          </div>
        </div>

        <div className={cx("can-wrap")}>
          <div className={cx("can-wrap-inner")}>
            <picture className={cx("picture")}>
              {imageUrl &&
                (() => {
                  const imageProps = {
                    ref: canRef,
                    className: cx("image"),
                    loading: imageLoading,
                    alt: imageAlt,
                    width: imageDimensions?.width,
                    height: imageDimensions?.height,
                  };
                  switch (imageSource) {
                    case "storyblok": {
                      return (
                        <img
                          {...imageProps}
                          src={getStoryblokImageUrl(imageUrl, {
                            width: 308 * 2,
                            height: undefined,
                          })}
                        />
                      );
                    }
                    default: {
                      return <img {...imageProps} src={imageUrl} />;
                    }
                  }
                })()}
            </picture>
          </div>
        </div>
      </div>
      <div className={cx("banderole-text-wrap")} aria-hidden="true">
        <div className={cx("banderole-text-wrap-inner")} ref={outlineRef}>
          <CosmosTitle
            className={cx("banderole-text")}
            aria-hidden={true}
            appearance={colorSchemeTextAppearance}
            data-appearance={colorSchemeTextAppearance}
          >
            {Array(10).fill(banderoleText).join(" ")}
          </CosmosTitle>
        </div>
      </div>
    </div>
  );
}
